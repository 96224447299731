import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom'
import APIRoute from '../routersAPI/routes.json';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import {FaSave} from "react-icons/fa";

export default function EditPaymentOrder() {
    const navigate = useNavigate();

    const { id } = useParams()
    const [urlVideo, setUrlVideo] = useState("")
    const [fileNumber, setFileNumber] = useState("")
    const [date, setDate] = useState("")
    const [hour, setHour] = useState("")
    const [idPaymentOrder, setPaymentOrder] = useState("")
    const [supervisor, setSupervisor] = useState("")
    const [checkList, setCheckLists] = useState([]);
    const [checked, setChecked] = useState([]);
    const [supervisorList, setSupervisorList] = useState([]);
    const [idPaymentOrderList, setPaymentOrderList] = useState([])
    const [propertyType, setPropertyType] = useState("");
    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const [useType, setUseType] = useState("");
    const [useTypeList, setUseTypeList] = useState([]);
    const [extension, setExtension] = useState("");
    const [materiality, setMateriality] = useState("");
    const [materialityList, setMaterialityList] = useState([]);

    const [validationError, setValidationError] = useState({})

    useEffect(() => {
        fetchAppraisal()
    }, [])

    const fetchAppraisal = async () => {
        await axios.get(APIRoute.paymentOrder + id).then(({ data }) => {
            setUrlVideo(data.appraisal[0]['urlVideo']);
            setFileNumber(data.appraisal[0]['fileNumber']);
            setDate(moment(data.appraisal[0]['meetingDate']).format('yyyy-MM-DD'));
            setHour(moment(data.appraisal[0]['meetingDate']).format('hh:mm'));
            setPaymentOrder(data.appraisal[0]['idPaymentOrder']);
            setSupervisor(data.appraisal[0]['idSupervisor']);
            setMateriality(data.appraisal[0]['idMateriality']);
            setUseType(data.appraisal[0]['idUseType']);
            setPropertyType(data.appraisal[0]['idPropertyType']);
            setExtension(data.appraisal[0]['extension']);
        })
    };

    const updateAppraisal = async (e) => {
        e.preventDefault();

        const formData = new FormData()
        formData.append('_method', 'PATCH');
        formData.append('id', id);
        formData.append('urlVideo', urlVideo);
        formData.append('fileNumber', fileNumber);
        formData.append('date', date);
        formData.append('paymentOrder', idPaymentOrder);
        formData.append('supervisor', supervisor);
        formData.append('checks', checked)
        formData.append('hour', hour);
        formData.append('propertyType', propertyType)
        formData.append('useType', useType);
        formData.append('extension', extension);
        formData.append('materiality', materiality);

        await axios.post(APIRoute.paymentOrder + id, formData).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            navigate("/")
        }).catch(({ response }) => {
            if (response.status === 422) {
                setValidationError(response.data.errors)
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error"
                })
            }
        })
    }

    useEffect(() => {
        fetchChecklists();
        fetchSupervisor();
        fetchClient();
        fetchMateriality();
        fetchPropertyType();
        fetchUseType();
    }, [])

    const handleToggle = c => () => {
        // return the first index or -1
        const clickedCategory = checked.indexOf(c);
        const all = [...checked];

        if (clickedCategory === -1) {
            all.push(c);
        } else {
            all.splice(clickedCategory, 1);
        }
        setChecked(all);
    };

    const fetchChecklists = async () => {
        await axios.get(APIRoute.checkList).then(({ data }) => {
            setCheckLists(data);
        })
    }

    const fetchSupervisor = async () => {
        await axios.get(APIRoute.supervisor).then(({ data }) => {
            setSupervisorList(data);
        })
    }

    const fetchClient = async () => {
        await axios.get(APIRoute.paymentClient).then(({ data }) => {
            setPaymentOrderList(data);
        })
    }

    const fetchUseType = async () => {
        await axios.get(APIRoute.getUseType).then(({ data }) => {
            setUseTypeList(data);
        })
    }
    const fetchPropertyType = async () => {
        await axios.get(APIRoute.getPropertyType).then(({ data }) => {
            setPropertyTypeList(data);
        })
    }
    const fetchMateriality = async () => {
        await axios.get(APIRoute.getMateriality).then(({ data }) => {
            setMaterialityList(data);
        })
    }
    return (
        <div className="container needs-validation">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-9">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Ingresar Tasacion</h4>
                            <hr />
                            <div className="form-wrapper">
                                {
                                    Object.keys(validationError).length > 0 && (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="alert alert-danger">
                                                    <ul className="mb-0">
                                                        {
                                                            Object.entries(validationError).map(([key, value]) => (
                                                                <li key={key}>{value}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <Form onSubmit={updateAppraisal}>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="urlVideo">
                                                <Form.Label column sm="2">LINK Videollamada</Form.Label>
                                                <Col sm="10">
                                                    <Form.Control type="text" size="lg" placeholder="Ingrese Link" required value={urlVideo} onChange={(event) => {
                                                        setUrlVideo(event.target.value)
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="fileNumber">
                                                <Form.Label column sm="2">Numero de orden</Form.Label>
                                                <Col sm="10">
                                                    <Form.Control type="text" size="lg" placeholder="Ingrese Número de orden" required value={fileNumber} onChange={(event) => {
                                                        setFileNumber(event.target.value)
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="date">
                                                <Form.Label column sm="2">Fecha reunión</Form.Label>
                                                <Col sm="10">
                                                    <Form.Control type="date" required value={date} onChange={(event) => {
                                                        setDate(event.target.value)
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="hour">
                                                <Form.Label column sm="2">Hora reunión</Form.Label>
                                                <Col sm="10">
                                                    <Form.Control type="time" required value={hour} onChange={(event) => {
                                                        setHour(event.target.value)
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="propertyType">
                                                <Form.Label column sm="2">Tipo de propiedad</Form.Label>
                                                <Col sm="10">
                                                    <Form.Select aria-label="Default select example" value={propertyType} onChange={(event) => {
                                                        setPropertyType(event.target.value)
                                                    }} >
                                                        <option value="" selected disabled="disabled">Seleccione tipo de propiedad</option>
                                                        {propertyTypeList.map((item, index) => (
                                                            <option value={item.id} key={index}>{item.name}</option>
                                                        )
                                                        )}
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="useType">
                                                <Form.Label column sm="2">Uso de propiedad</Form.Label>
                                                <Col sm="10">
                                                    <Form.Select aria-label="Default select example" value={useType} onChange={(event) => {
                                                        setUseType(event.target.value)
                                                    }} >
                                                        <option value="" selected disabled="disabled">Seleccione uso de propiedad</option>
                                                        {useTypeList.map((item, index) => (
                                                            <option value={item.id} key={index}>{item.name}</option>
                                                        )
                                                        )}
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="materiality">
                                                <Form.Label column sm="2">Tipo de materialidad</Form.Label>
                                                <Col sm="10">
                                                    <Form.Select aria-label="Default select example" value={materiality} onChange={(event) => {
                                                        setMateriality(event.target.value)
                                                    }} >
                                                        <option value="" selected disabled="disabled">Seleccione tipo de materialidad</option>
                                                        {materialityList.map((item, index) => (
                                                            <option value={item.id} key={index}>{item.name}</option>
                                                        )
                                                        )}
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="extension">
                                                <Form.Label column sm="2">¿Tiene ampliación?</Form.Label>
                                                <Col sm="10">
                                                    <Form.Select aria-label="Default select example" value={extension} onChange={(event) => {
                                                        setExtension(event.target.value)
                                                    }} >
                                                        <option value="" selected disabled="disabled">Seleccione una alternativa</option>
                                                        <option value="1">Si</option>
                                                        <option value="0">No</option>

                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="paymentOrder">
                                                <Form.Label column sm="2">Orden de pago</Form.Label>
                                                <Col sm="10">
                                                    <Form.Select aria-label="Default select example" value={idPaymentOrder} onChange={(event) => {
                                                        setPaymentOrder(event.target.value)
                                                    }} >
                                                        <option value="-1" selected disabled="disabled">Seleccione orden de pago</option>
                                                        {idPaymentOrderList.map((item, index) => (
                                                            <option key={index} value={item.idP}>{item.nameClient + " / " + item.rut + " / " + item.paymentDocument}</option>
                                                        )
                                                        )}
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="supervisor">
                                                <Form.Label column sm="2">Supervisor(a)</Form.Label>
                                                <Col sm="10">
                                                    <Form.Select aria-label="Default select example" value={supervisor} onChange={(event) => {
                                                        setSupervisor(event.target.value)
                                                    }} >
                                                        <option value="-1" selected disabled="disabled">Seleccione supervisor</option>
                                                        {supervisorList.map((item, index) => (
                                                            <option value={item.id} key={index}>{item.supervisorName}</option>
                                                        )
                                                        )}
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="fileNumber">
                                                <Form.Label column sm="2" >Check List Tipo De Bien</Form.Label>
                                                <Col sm="10">
                                                    {checkList.map((item, index) => (
                                                        (item.status === 1) ?
                                                            <Col md="12" key={item.id}>
                                                                <input value={item.id} type="checkbox" onChange={handleToggle(item.id)} />
                                                                <span> {item.checkName}</span>
                                                            </Col>
                                                            : ""
                                                    )
                                                    )}
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                                    <FaSave/>&nbsp;Guardar
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}