import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import APIRoute from '../routersAPI/routes.json';
import Swal from 'sweetalert2'
import { MDBDataTableV5 } from 'mdbreact';
import { Button } from 'react-bootstrap';
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';

export default function List() {

    const [appraisal, setAppraisal] = useState([])
    const headCells = [
        {
            label: 'Numero Expediente',
            field: 'fileNumber',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Name',
            },
        },
        {
            label: 'Estado',
            field: 'status',
        },
        {
            label: 'Fecha reunión',
            field: 'meetingDate',
        },
        {
            label: 'Fecha finalización',
            field: 'accessDate',
        },
        {
            label: 'Acción',
            field: 'actionBtn'
        }
    ];
    const [datatable, setDatatable] = useState({
        columns: headCells,
        rows: []

    });
    useEffect(() => {
        fetchAppraisal()
    }, [APIRoute.appraisal])

    const fetchAppraisal = async () => {
        await axios.get(APIRoute.appaisalbysupervisor).then(({ data }) => {
            setAppraisal(data);

            setDatatable({
                columns: headCells,
                rows: data.map(appraisal => {
                    if (parseInt(appraisal.idSupervisor) === parseInt(sessionStorage.getItem('idUser'))) {
                        let status = "";
                        let accessDate = "";
                        let callTime = "";
                        if (appraisal.status === 1) {
                            status = "REALIZADA";
                            accessDate = appraisal.accessDate;
                            callTime = appraisal.callTime;
                        } else {
                            status = "PENDIENTE";
                            accessDate = "NO APLICA";
                            callTime = "NO APLICA";
                        }
                        return {
                            fileNumber: appraisal.fileNumber,
                            status: status,
                            meetingDate: appraisal.meetingDate,
                            accessDate: accessDate,
                            actionBtn:
                                <>
                                    <div className="d-grid col-md-3 col-lg-3 col-sm-7" style={{ fontSize: '12px' }}>
                                        {appraisal.status === 0 ?
                                            <Link to={APIRoute.joinToAppraisal + appraisal.fileNumber}>
                                                <Button className='btn btn-success me-2'>Ingresar reunión
                                                </Button>
                                            </Link>
                                            :
                                            <a href={APIRoute.routeAPIFile + appraisal.urlVideo} target="_blank" rel="noopener noreferrer">
                                                <Button className='btn btn-info me-2'>
                                                    Descargar reunión
                                                </Button>

                                            </a>
                                        }

                                    </div>

                                </>
                        }
                    }else{
                        console.log(appraisal.idSupervisor + " " + sessionStorage.getItem('idUser'));
                    }
                })
            })

        })
    }

    const deleteAppraisal = async (id) => {
        const isConfirm = await Swal.fire({
            title: '¿Estás seguro?',
            text: "No podras revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
            return result.isConfirmed
        });

        if (!isConfirm) {
            return;
        }

        await axios.delete(APIRoute.appraisal + id).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            fetchAppraisal()
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <MDBDataTableV5
                        hover
                        entriesOptions={[5, 20, 25]}
                        entries={5}
                        pagesAmount={4}
                        searchTop searchBottom={false} searchLabel="Buscar"
                        exportToCSV
                        responsive
                        data={datatable}
                    />
                </div>
            </div>
        </div>
    )
}