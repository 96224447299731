import React, { useState } from "react";
import axios from 'axios';
import APIRoute from '../routersAPI/routes.json';

import SupervisorMenu from "../menu/supervisor.component";
import CoordinationMenu from "../menu/coordination.component";
import AdminMenu from "../menu/admin.component";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from "../../logo.png";


import Button from 'react-bootstrap/Button'
import { MdPassword } from "react-icons/md";



export default function Authentication() {
    const [errorMessages, setErrorMessages] = useState({});
    const [uName, setUName] = useState("");
    const [uPass, setUPass] = useState("");
    const [validationError, setValidationError] = useState({})
    const errors = {
        uname: "invalid username",
        pass: "invalid password"
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData()

        formData.append('uName', uName)
        formData.append('uPass', uPass)

        await axios.post(APIRoute.loginC, formData).then(({ data }) => {
            if (data.status === 200) {
                sessionStorage.setItem("session", 1);
                sessionStorage.setItem('typeLogin', data.typeLogin);
                sessionStorage.setItem('idUser', data.idUser)
                window.location.reload();
            } else {
                sessionStorage.setItem("session", 0);
                sessionStorage.setItem('typeLogin', "-");
                setErrorMessages({ name: "uname", message: errors.uname });
            }
        });
    };

    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );
    return (
        <>
            {sessionStorage.getItem('session') == 0 || sessionStorage.getItem('session') === null ?
                <div className="container" position="top">
                    <div className="row justify-content-center align-items-center" >
                        <div className="col-12 col-sm-12 col-md-4 my-4">
                            <div className="card" >
                                <div className="card-body">
                                <img src = {logo} alt = "Logo" style={{bottom: '20px', right: '20px',width: '100px', marginTop: 10, marginLeft: 100 }}/>
                                    <h4 className="card-title"style={{marginTop: 10, marginLeft: 100 }} >Bienvenido</h4>
                                    <hr />
                                    <div className="form-wrapper">
                                        {
                                            Object.keys(validationError).length > 0 && (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="alert alert-danger">
                                                            <ul className="mb-0">
                                                                {
                                                                    Object.entries(validationError).map(([key, value]) => (
                                                                        <li key={key}>{value}</li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <Form onSubmit={handleSubmit}>
                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="checkName">
                                                        <Form.Label>Nombre</Form.Label>
                                                        <Form.Control type="text" placeholder="Ingrese su nombre de usuario"value={uName} onChange={(event) => {
                                                            setUName(event.target.value)
                                                        }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="my-3">
                                                <Col>
                                                    <Form.Group controlId="uPass">
                                                        <Form.Label>Contraseña</Form.Label>
                                                        <Form.Control type="password"  placeholder="Ingrese su contraseña"value={uPass} onChange={(event) => {
                                                            setUPass(event.target.value)
                                                        }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Button variant="primary" className="mt-2" size="lg" block="block" type="submit" style={{marginTop: 10, marginLeft: 80 }} >
                                                Ingresar
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                <>
                    {sessionStorage.getItem('typeLogin') == 'coordinator' ?
                        <CoordinationMenu /> :
                        sessionStorage.getItem('typeLogin') == 'admin' ?
                            <AdminMenu />
                            : <SupervisorMenu />
                    }

                </>}

        </>

    );
}