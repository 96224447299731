import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom';
import APIRoute from '../routersAPI/routes.json';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import { BiLogIn } from "react-icons/bi";


export default function JoinToMeeating() {
    const navigate = useNavigate();

    const { fileNumber } = useParams()
    const [URLVideo, setURLVideo] = useState("");
    const [meetingDate, setMeetingDate] = useState("");
    const [checkList, setCheckList] = useState([]);
    const [file, setFile] = useState("");
    const [date, setDate] = useState("")
    const [hour, setHour] = useState("")
    const [paymentOrder, setPaymentOrder] = useState("")
    const [supervisor, setSupervisor] = useState("")
    const [checked, setChecked] = useState([]);
    const [validationError, setValidationError] = useState({});
    const [supervisorList, setSupervisorList] = useState([]);
    const [paymentOrderList, setPaymentOrderList] = useState([]);
    const [propertyType, setPropertyType] = useState("");
    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const [useType, setUseType] = useState("");
    const [useTypeList, setUseTypeList] = useState([]);
    const [extension, setExtension] = useState("");
    const [materiality, setMateriality] = useState("");
    const [materialityList, setMaterialityList] = useState([]);
    const [formatError, setFormatError] = useState({});
    const [fileNumber2, setFileNumber2] = useState("")
    const [id, setId] = useState("");
    let auxTypeAccept = false;
    const str = 'Prueba'
    const formatAccept = ["excel", "video", "sheet", ".mp4", ".mov", ".wmv", ".mkv", ".avi", ".webm"];
    const fi = document.getElementById('file');
    const handleClick = () => {


    }


    useEffect(() => {
        fetchCheckList();
        fetchSupervisor();
        fetchClient();
        fetchMateriality();
        fetchPropertyType();
        fetchUseType();
    }, [])

    const fetchCheckList = async () => {
        await axios.get(APIRoute.joinToAppraisalAPI + fileNumber).then(({ data }) => {
            setCheckList(data.checkList)
            setURLVideo(data.appraisal['urlVideo'])
            setMeetingDate(data.appraisal['meetingDate'])
            setExtension(data.appraisal['extension']);
            setMateriality(data.appraisal['idMateriality']);
            setUseType(data.appraisal['idUseType']);
            setPropertyType(data.appraisal['idPropertyType']);
            setDate(moment(data.appraisal['meetingDate']).format('yyyy-MM-DD'));
            setHour(moment(data.appraisal['meetingDate']).format('hh:mm'));
            setPaymentOrder(data.appraisal['idPaymentOrder']);
            setSupervisor(data.appraisal['idSupervisor']);
            setId(data.appraisal['idAppraisal']);
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }
    const fetchSupervisor = async () => {
        await axios.get(APIRoute.supervisor).then(({ data }) => {
            setSupervisorList(data);
        })
    }

    const fetchClient = async () => {
        await axios.get(APIRoute.paymentClient).then(({ data }) => {
            setPaymentOrderList(data);
        })
    }

    const fetchUseType = async () => {
        await axios.get(APIRoute.getUseType).then(({ data }) => {
            setUseTypeList(data);
        })
    }
    const fetchPropertyType = async () => {
        await axios.get(APIRoute.getPropertyType).then(({ data }) => {
            setPropertyTypeList(data);
        })
    }
    const fetchMateriality = async () => {
        await axios.get(APIRoute.getMateriality).then(({ data }) => {
            setMaterialityList(data);
        })
    }

    const saveMeeting = async (e) => {
        const path = {
            removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove',
            saveUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Save'
        };
        for (var i = 0; i < formatAccept.length; i++) {
            if (file['type'].includes(formatAccept[i])) {
                auxTypeAccept = true;
            }
        }
        if (auxTypeAccept) {
            e.preventDefault();
            const formData = new FormData();
            formData.append('_method', 'PATCH');
            formData.append('status', 1);
            formData.append('accessDate', new Date().toISOString().slice(0, 10));
            formData.append('fileNumber', fileNumber);
            formData.append('file', file);
            console.log(file);
            await axios.post(APIRoute.appraisal + fileNumber, formData).then(({ data }) => {
                console.log(data);
                Swal.fire({
                    icon: "success",
                    text: data.message
                })
                navigate('/')       
            }).catch(({ response }) => {
                if (response.status === 422) {
                    setValidationError(response.data.errors)
                } else {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error"
                    })
                }
            })
        } else {
            Swal.fire({
                icon: "error",
                text: "formato malito"
            })
        }
    }
    const updateAppraisal = async (e) => {
        e.preventDefault();

        const formData = new FormData()
        formData.append('_method', 'PATCH');
        formData.append('id', id);
        formData.append('urlVideo', URLVideo);
        formData.append('fileNumber', fileNumber);
        formData.append('date', date);
        formData.append('hour', hour);
        formData.append('propertyType', propertyType)
        formData.append('useType', useType);
        formData.append('extension', extension);
        formData.append('materiality', materiality);

        await axios.post(APIRoute.paymentOrderSupervisor + id, formData).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            /*navigate("/appraisal/jointo/" + fileNumber)*/
        }).catch(({ response }) => {
            if (response.status === 422) {
                setValidationError(response.data.errors)
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error"
                })
            }
        })
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-URLVideo"><BiLogIn />&nbsp;Ingresar a la reunión {meetingDate}</h4>
                            <hr />
                            <span>Link de la reunión: </span><a href={URLVideo} target="_blank">{URLVideo}</a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-checkName">CheckList</h4>
                            <hr />
                            <div className="form-wrapper">
                                <Form onSubmit={saveMeeting}>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group controlId="Name">
                                                {checkList.map((item, index) => (
                                                    <Col md="10" key={item.id}>
                                                        <input value={item.id} type="checkbox" />
                                                        <span> {item.checkName}</span>
                                                    </Col>
                                                )
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group controlId="file" className="mb-3">
                                                <Form.Label>Grabación (.xls,.xlsx,.mp4, .mov,.wmv,.mkv,.avi,.webm)</Form.Label>
                                                <Form.Control minFileSize={10000} maxFileSize={28400000} type="file" name="upload" accept=".xls,.xlsx,.mp4, .mov,.wmv,.mkv,.avi,.webm" onChange={(event) => { setFile(event.target.files[0]); handleClick(); }}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button  variant="success" className="mt-2" size="lg" block="block" type="submit">
                                        Finalizar
                                    </Button>
                                </Form>
                                <hr />
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-checkName">Información de la tasación</h4>
                            <hr />
                            <Form onSubmit={updateAppraisal}>
                                <Row className="my-3">
                                    <Col>
                                        <Form.Group as={Row} className="mb-3" controlId="fileNumber">
                                            <Form.Label column sm="2">Numero de orden</Form.Label>
                                            <Col sm="10">
                                                <Form.Control readOnly={true} type="text" size="lg" placeholder="Ingrese Número de orden" required value={fileNumber} onChange={(event) => {
                                                    setFileNumber2(event.target.value)
                                                }} />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <Form.Group as={Row} className="mb-3" controlId="date">
                                            <Form.Label column sm="2">Fecha reunión</Form.Label>
                                            <Col sm="10">
                                                <Form.Control readOnly={true} type="date" required value={date} onChange={(event) => {
                                                    setDate(event.target.value)
                                                }} />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <Form.Group as={Row} className="mb-3" controlId="hour">
                                            <Form.Label column sm="2">Hora reunión</Form.Label>
                                            <Col sm="10">
                                                <Form.Control readOnly={true} type="time" required value={hour} onChange={(event) => {
                                                    setHour(event.target.value)
                                                }} />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <Form.Group as={Row} className="mb-3" controlId="propertyType">
                                            <Form.Label column sm="2">Tipo de propiedad</Form.Label>
                                            <Col sm="10">
                                                <Form.Select aria-label="Default select example" value={propertyType} onChange={(event) => {
                                                    setPropertyType(event.target.value)
                                                }} >
                                                    <option value="-1" selected disabled="disabled">Seleccione tipo de propiedad</option>
                                                    {propertyTypeList.map((item, index) => (
                                                        <option value={item.id} key={index}>{item.name}</option>
                                                    )
                                                    )}
                                                </Form.Select>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <Form.Group as={Row} className="mb-3" controlId="useType">
                                            <Form.Label column sm="2">Uso de propiedad</Form.Label>
                                            <Col sm="10">
                                                <Form.Select aria-label="Default select example" value={useType} onChange={(event) => {
                                                    setUseType(event.target.value)
                                                }} >
                                                    <option value="-1" selected disabled="disabled">Seleccione uso de propiedad</option>
                                                    {useTypeList.map((item, index) => (
                                                        <option value={item.id} key={index}>{item.name}</option>
                                                    )
                                                    )}
                                                </Form.Select>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <Form.Group as={Row} className="mb-3" controlId="materiality">
                                            <Form.Label column sm="2">Tipo de materialidad</Form.Label>
                                            <Col sm="10">
                                                <Form.Select aria-label="Default select example" value={materiality} onChange={(event) => {
                                                    setMateriality(event.target.value)
                                                }} >
                                                    <option value="-1" selected disabled="disabled">Seleccione tipo de materialidad</option>
                                                    {materialityList.map((item, index) => (
                                                        <option value={item.id} key={index}>{item.name}</option>
                                                    )
                                                    )}
                                                </Form.Select>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <Form.Group as={Row} className="mb-3" controlId="extension">
                                            <Form.Label column sm="2">¿Tiene ampliación?</Form.Label>
                                            <Col sm="10">
                                                <Form.Select aria-label="Default select example" value={extension} onChange={(event) => {
                                                    setExtension(event.target.value)
                                                }} >
                                                    <option value="-1" selected disabled="disabled">Seleccione una alternativa</option>
                                                    <option value="1">Si</option>
                                                    <option value="0">No</option>

                                                </Form.Select>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button variant="info" className="mt-2" size="lg" block="block" type="submit">
                                    Corregir Información
                                </Button>
                                <div className="form-wrapper">
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}