import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Nav, Navbar, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import axios from 'axios';
import APIRoute from '../routersAPI/routes.json';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import CreateCheckList from "../checklist/create.component";
import CheckListList from "../checklist/list.component";
import EditCheckList from "../checklist/edit.component";
import Payments_Orders from "../payment_order/list.component";
import CreatePayments_Orders from "../payment_order/create.component";
import EditPayments_Orders from "../payment_order/edit.component";
import EditClient from "../client/edit.component";
import Appraisals from "../appraisal/list.component";
import SeeMeating from "../appraisal/see.component";
import JoinMeating from "../appraisal/jointo.component";
import logo from "../../logo.png";
import ClientList from '../client/list.component';
import CreateClient from '../client/create.component';
import { FiLogOut } from "react-icons/fi";

export default function CoordinationMenu() {
    return (
        <Router>
            <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
                <Container>
                    <Navbar.Brand className="navbar-brand text-white">
                        <Link to={"/"} className="navbar-brand text-white">
                            <img src={logo} alt="Logo" style={{ bottom: '20px', right: '20px', width: '100px' }} />
                            <div style={{ fontSize: '15px' }}>Valuaciones SPA</div>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-brand text-white" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Link to={"/"} className="navbar-brand text-white">
                                Home
                            </Link>
                            <Link to={"/payment_orders"} className="navbar-brand text-white">
                                Orden de trabajo
                            </Link>
                            <Link to={"/checklists"} className="navbar-brand text-white">
                                Checklist
                            </Link>
                            <Link to={"/clients"} className="navbar-brand text-white">
                                Clientes
                            </Link>
                            <Link to={"/"} className="navbar-brand text-white">
                            <>
                                {sessionStorage.getItem('session') != 0 || sessionStorage.getItem('session') != null ?
                                    <div className='logout'
                                        onClick={(e) => {
                                            sessionStorage.removeItem("session");
                                            sessionStorage.removeItem('typeLogin');
                                            window.location.reload();
                                        }}
                                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FiLogOut />&nbsp;Cerrar sesión</div>
                                    : <></>
                                }
                            </>
                            </Link> 
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container className="mt-5">
                <Row>
                    <Col md={12}>
                        <Routes>
                            <Route exact path="*" element={<Payments_Orders />} />
                            {/* ===============RUTAS CHECKLIST =============== */}
                            <Route exact path="/checklist/create" element={<CreateCheckList />} />
                            <Route exact path="/checklists" element={<CheckListList />} />
                            <Route exact path="/checklist/edit/:id" element={<EditCheckList />} />
                            {/* ===============RUTAS CHECKLIST =============== */}
                            {/* ===========RUTAS ORDEN DE TRABAJO ============ */}
                            <Route exact path="payment_orders" element={<Payments_Orders />} />
                            <Route exact path="/payment_orders/create" element={<CreatePayments_Orders />} />
                            <Route exact path="/payment_orders/edit/:id" element={<EditPayments_Orders />} />
                            {/* ===========RUTAS ORDEN DE TRABAJO ============ */}
                            {/* ===========RUTAS CLIENTES ==================== */}
                            <Route exact path="clients" element={<ClientList />} />
                            <Route exact path="/client/create" element={<CreateClient />} />
                            <Route exact path="/client/edit/:id" element={<EditClient />} />
                            {/* ===========RUTAS CLIENTES ==================== */}
                            {/* ===========RUTAS CLIENTES ==================== */}
                            <Route exact path="/client/logout" element={<CreateClient/>} />
                            {/* ===========RUTAS CLIENTES ==================== */}

                        </Routes>
                    </Col>
                </Row>
            </Container>
        </Router>
    );
}