import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import APIRoute from '../routersAPI/routes.json';
import Swal from 'sweetalert2'
import { MDBDataTableV5 } from 'mdbreact';
import { FcPlus } from "react-icons/fc";
import { MdDeleteForever, MdOutlineModeEditOutline } from "react-icons/md";

export default function ClientList() {
    const [clientlist, setClientlists] = useState([])
    const headCells = [
        {
            label: 'Nombre',
            field: 'nameClient',

        },
        {
            label: 'Rut',
            field: 'rut',
        },
        {
            label: 'Telefono',
            field: 'numberClient',
        },
        {
            label: 'Correo',
            field: 'email',
        },
        {
            label: 'Comuna',
            field: 'name',
        },
        {
            label: 'Acción',
            field: 'actionBtn'
        }
    ];
    const [datatable, setDatatable] = useState({
        columns: headCells,
        rows: []

    });
    useEffect(() => {
        fetchClient()
    }, [APIRoute.client])

    const fetchClient = async () => {
        await axios.get(APIRoute.client).then(({ data }) => {
            setClientlists(data);
            setDatatable({
                columns: headCells,
                rows: data.map(client => {
                    let accessDate = "";
                    let callTime = "";
                    return {
                        nameClient: client.nameClient,
                        rut: client.rut,
                        numberClient: client.numberClient,
                        email: client.emailClient,
                        name: client.name,
                        actionBtn:
                            <>
                                <div className="d-grid col-md-3 col-lg-3 col-sm-7" style={{ fontSize: '12px'}}>
                                    <Link to={APIRoute.editClient + client.id} >
                                        <Button title="Editar" className='btn btn-success'>
                                        <MdOutlineModeEditOutline style={{margin: 0, fontSize: '20px' }}/>Editar
                                        </Button>                                       
                                    </Link>
                                    <Button title="Eliminar" className='btn btn-danger' onClick={() => deleteClientlist(client.id)} >
                                        <MdDeleteForever style={{margin: 0, fontSize: '20px' }}/>
                                    </Button>

                                </div>
                            </>
                    }
                })
            })
        })
    }
    const deleteClientlist = async (id) => {
        const isConfirm = await Swal.fire({
            title: '¿Estas seguro?',
            text: "No podras revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
            return result.isConfirmed
        });

        if (!isConfirm) {
            return;
        }
        await axios.delete(APIRoute.client + id).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            fetchClient()
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }
    return (
        <div className="container">
            <div className="row">
                <div className='col-12'>
                    <Link className='btn btn-primary mb-2 float-end' to={APIRoute.createClient}>
                        <FcPlus style={{ fontSize: '25px' }}/>&nbsp;Crear nuevo cliente
                    </Link>
                </div>
                <div className="col-12">
                    <MDBDataTableV5
                        hover
                        entriesOptions={[5, 20, 25]}
                        entries={5}
                        pagesAmount={4}
                        searchTop searchBottom={false} searchLabel="Buscar"
                        exportToCSV
                        responsive
                        data={datatable}
                    />
                </div>
            </div>
        </div>
    )
}