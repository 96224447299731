import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Nav, Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import logo from "../../logo.png";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Appraisals from "../appraisal/list.component";
import SeeMeating from "../appraisal/see.component";
import JoinMeating from "../appraisal/jointo.component";
import { FiLogOut } from "react-icons/fi";

export default function SupervisorMenu() {
    return (
        <Router>
            <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
                <Container>
                    <Navbar.Brand className="navbar-brand text-white">
                        <Link to={"/"} className="navbar-brand text-white">
                        <img src = {logo} alt = "Logo" style={{bottom: '20px', right: '20px',width: '100px'  }}/>
                        <div style={{fontSize:'15px'}}>Valuaciones SPA</div>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-brand text-white" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Link to={"/"} className="navbar-brand text-white">
                                Home
                            </Link>
                            <Link to={"/appraisals"} className="navbar-brand text-white">
                                Tasaciones
                            </Link>
                            <Link to={"/"} className="navbar-brand text-white">
                            <>
                                {sessionStorage.getItem('session') != 0 || sessionStorage.getItem('session') != null ?
                                    <div className='logout'
                                        onClick={(e) => {
                                            sessionStorage.removeItem("session");
                                            sessionStorage.removeItem('typeLogin');
                                            window.location.reload();
                                        }}
                                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FiLogOut />&nbsp;Cerrar sesión</div>
                                    : <></>
                                }
                            </>
                            </Link> 
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Container className="mt-5">
                <Row >
                    <Col md={12}>
                        <Routes>
                            <Route exact path="*" element={<Appraisals />} />
                            {/* ===========RUTAS TASACIONES ================== */}
                            <Route exact path="appraisals" element={<Appraisals />} />
                            <Route exact path="/appraisal/jointo/:fileNumber" element={<JoinMeating />} />
                            {/* ===========RUTAS TASACIONES ================== */}
                        </Routes>
                    </Col>
                </Row>
            </Container>
        </Router>
    );
}