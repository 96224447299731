import React, { useState, useEffect } from "react";
import Authentication from "./components/Auth/Authentication";


export default function App() {
  return (

    <div className="center">
      <Authentication />
    </div>
  );
}