import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import ClientList from "./list.component";
import APIRoute from "../routersAPI/routes.json";

export default function EditUser() {
    const navigate = useNavigate();

    const { id } = useParams()
    const [name, setName] = useState("");
    const [rut, setRut] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [commune, setCommune] = useState("");
    const [communeList, setCommuneList] = useState([]);
    const [status, setStatus] = useState([]);
    const [validationError, setValidationError] = useState({});

    useEffect(() => {
        fetchCommune();
        fetchClient();
    }, [])

    const fetchClient = async () => {
        await axios.get(APIRoute.client+id).then(({ data }) => {
            
            setName(data.client['nameClient']);
            setRut(data.client['rut']);
            setPhone(data.client['numberClient']);
            setEmail(data.client['emailClient']);
            setCommune(data.client['idCommune']);
            setStatus(data.client['status']);
        })
    };

    const updateClientList = async (e) => {
        e.preventDefault();

        const formData = new FormData()
        formData.append('_method', 'PATCH');
        formData.append('id', id)
        formData.append('name', name)
        formData.append('rut', rut)
        formData.append('phone', phone)
        formData.append('email', email)
        formData.append('comuna', commune)
        formData.append('status', status)
        await axios.post(APIRoute.client + id, formData).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            navigate("/clients")
        }).catch(({ response }) => {
            if (response.status === 422) {
                setValidationError(response.data.errors)
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error"
                })
            }
        })
    }
    const fetchCommune = async () => {
        await axios.get(APIRoute.getCommune).then(({ data }) => {
            setCommuneList(data);
        })
    }
    return (
        <div className="container needs-validation">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Ingresar Cliente</h4>
                            <hr />
                            <div className="form-wrapper">
                                {
                                    Object.keys(validationError).length > 0 && (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="alert alert-danger">
                                                    <ul className="mb-0">
                                                        {
                                                            Object.entries(validationError).map(([key, value]) => (
                                                                <li key={key}>{value}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <Form onSubmit={updateClientList}>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="name">
                                                <Form.Label column sm="2">Nombre</Form.Label>
                                                <Col sm="10">
                                                    <Form.Control type="text" size="lg" placeholder="Ingrese Nombre Cliente" required value={name} onChange={(event) => {
                                                        setName(event.target.value)
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="rut">
                                                <Form.Label column sm="2">Rut</Form.Label>
                                                <Col sm="10">
                                                    <Form.Control type="text" size="lg" placeholder="Ingrese Rut" required value={rut} onChange={(event) => {
                                                        setRut(event.target.value)
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="phone">
                                                <Form.Label column sm="2">Telefono</Form.Label>
                                                <Col sm="10">
                                                    <Form.Control type="text"  size="lg" placeholder="Ingrese Telefono" required value={phone} onChange={(event) => {
                                                        setPhone(event.target.value)
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="email">
                                                <Form.Label column sm="2">Correo</Form.Label>
                                                <Col sm="10">
                                                    <Form.Control type="text" size="lg" placeholder="Ingrese Correo" required value={email} onChange={(event) => {
                                                        setEmail(event.target.value)
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                    <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="commune">
                                                <Form.Label column sm="2">Comuna</Form.Label>
                                                <Col sm="10">
                                                    <Form.Select aria-label="Default select example" value={commune} onChange={(event) => {
                                                        setCommune(event.target.value)
                                                    }} >
                                                        <option value=""  selected disabled="disabled">Seleccione su Comuna</option>
                                                        {communeList.map((item, index) => (
                                                            <option key={index} value={item.id}>{item.name} [Region: {item.region}]</option>
                                                        )
                                                        )}
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                    <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="status">
                                                <Form.Label column sm="2">Estado</Form.Label>
                                                <Col sm="10">
                                                    <Form.Select aria-label="Default select example" value={status} onChange={(event) => {
                                                        setStatus(event.target.value)
                                                    }} >
                                                        <option value=""  selected disabled="disabled">Seleccione Estado del Tasador</option>
                                                        <option value="1">Activo</option>
                                                        <option value="0">Inactivo</option>
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                                        Guardar
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}