import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import APIRoute from '../routersAPI/routes.json';
import Swal from 'sweetalert2'
import { MDBDataTableV5 } from 'mdbreact';
import { FcPlus } from "react-icons/fc";
import { MdDeleteForever, MdOutlineModeEditOutline } from "react-icons/md";

export default function List() {
    const [paymentOrder, setPaymentOrder] = useState([])
    const headCells = [
        {
            label: 'Numero Expediente',
            field: 'fileNumber',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Name',
            },
        },
        {
            label: 'Estado',
            field: 'status',
        },
        {
            label: 'Fecha reunión',
            field: 'meetingDate',
        },
        {
            label: 'Fecha acceso',
            field: 'accessDate',
        },
        {
            label: 'Supervisor',
            field: 'supervisor',
        },
        {
            label: 'Fecha pago',
            field: 'paymentDate',
        },
        {
            label: 'Acción',
            field: 'actionBtn'
        }
    ];
    const [datatable, setDatatable] = useState({
        columns: headCells,
        rows: []
    });
    useEffect(() => {
        fetchPaymentOrder()
    }, [])
    const fetchPaymentOrder = async () => {
        await axios.get(APIRoute.paymentOrder).then(({ data }) => {
            setPaymentOrder(data);
            setDatatable({
                columns: headCells,
                rows: data.map(paymentOrder => {
                    let status = "";
                    let accessDate = "";
                    let callTime = "";
                    if (paymentOrder.status === 1) {
                        status = "REALIZADA";
                        accessDate = paymentOrder.accessDate;
                        callTime = paymentOrder.callTime;
                    } else {
                        status = "PENDIENTE";
                        accessDate = "NO APLICA";
                        callTime = "NO APLICA";
                    }
                    return {
                        fileNumber: paymentOrder.fileNumber,
                        status: status,
                        meetingDate: paymentOrder.meetingDate,
                        accessDate: accessDate,
                        supervisor: paymentOrder.supervisorName,
                        paymentDate: paymentOrder.paymentDate,
                        actionBtn:
                            <div className="col-md-8 my-7" >
                                <div className="d-grid">
                                    <Link to={APIRoute.editPaymentOrder + paymentOrder.idAppraisal}>
                                            <Button title="Editar" className='btn btn-success'>
                                                <MdOutlineModeEditOutline style={{ fontSize: '20px' }} />&nbsp;Editar
                                            </Button>
                                    </Link>
                                        <Button title="Eliminar" className='btn btn-danger ' onClick={() => deletePaymentOrder(paymentOrder.idAppraisal)}>
                                            <MdDeleteForever style={{margin: 0, fontSize: '20px' }} />
                                        </Button>
                                </div>
                            </div>
                    }
                })
            })
        })
    }

    const deletePaymentOrder = async (id) => {
        const isConfirm = await Swal.fire({
            title: '¿Estás seguro?',
            text: "No podras revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
            return result.isConfirmed
        });

        if (!isConfirm) {
            return;
        }

        await axios.delete(APIRoute.paymentOrder + id).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            fetchPaymentOrder()
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }

    return (
        <div className="container">
            <div className="row">
                <div className='col-12'>
                    <Link className='btn btn-primary mb-2 float-end' to={APIRoute.createPaymentOrder}>
                        <FcPlus style={{ fontSize: '25px' }} />&nbsp;&nbsp;Crear nueva tasación
                    </Link>
                </div>
                <div className="col-12">
                    <MDBDataTableV5
                        hover
                        entriesOptions={[5, 20, 25]}
                        entries={5}
                        pagesAmount={4}
                        searchTop searchBottom={false} searchLabel="Buscar N° expediente"
                        exportToCSV
                        responsive
                        data={datatable}
                    />
                </div>
            </div>
        </div>
    )
}